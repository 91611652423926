import {getHeader, getHeaderonlyLogo, getPDFHeaderAgency} from "@/util/PDFDokumente/PDFHeader";
import {getPDFFooterAgency} from "@/util/PDFDokumente/PDFFooter";
import {getStyles} from "@/util/PDFDokumente/PDFStyles";
import moment from "moment";
import {formatPrice} from "@/util/currency";

export const pdfReservierungKunde = (order, index, userinfo, pDomain, logo) => {
    let docDefinition = {

        pageMargins: [50, 80, 40, 100],
        info: {
            title: pDomain,
            author: pDomain,
            subject: 'Reservierung_Kunde',
            keywords: 'Reservierung Kunde',
            creator: pDomain,
            producer: pDomain,
        },

        header: getHeaderonlyLogo(logo),

        footer: getPDFFooterAgency(userinfo),

        content: getPDFContentRechnung(order, index, userinfo),

        styles: getStyles()


    }
    return docDefinition;
}

export const
    getPDFContentRechnung = (order, index, userinfo) => {
        let res = order.order_id.split("-");
        let reservierungsnr = +res[2];
        let year = new Date().getFullYear();
        let date = moment(new Date()).format("DD.MM.YYYY");


        let rowheader = [];
        rowheader.push(
            //---------------------------- Tabellenkopf -------------------------------
            {text: 'Beschreibung'},
            {text: 'Betrag', alignment: 'right'}
        );


        let placeAdress = [];
        placeAdress.push(
            //---------------------------- Zeile 1 (bleibt immer gleich) -------------------------------
            [
                {
                    text: 'Unterkunft: ' + order.hotelinfos.hotelname,
                    margin: [0, 8, 0, 0]
                },
                {
                    text: 'Adresse: ' + order.hotelinfos.hoteladress,
                    margin: [0, 3, 0, 0]
                }
            ],
            [
                {
                    // text: (formatPrice(inv.payment) + ' €'),
                    // Erste Zeile benötigt keinen Preis, daher leer.
                    text: '',
                    alignment: 'right',
                    margin: [0, 8, 0, 0],

                },


                // {
                //     text: (order.hotelinfos.total_price_eur > 0 ? '(' + formatPrice(order.hotelinfos.total_price) + ' ' + order.hotelinfos.currency + ')' : ""),
                //     alignment: 'right', fontSize: 9,
                //     margin: [0, 2, 0, 0]
                // }
            ],
        );

        let groups = [];
        let prices = [];
        for (let i = 0; i < order.hotelinfos.gruppe.length; i++) {


            if (order.hotel_owner === '0' || order.hotel_owner === '1') {
                groups.push(
                    [
                        {
                            text:
                                [
                                    {
                                        text: 'Gruppe #' + (i + 1) + ' - Zimmer: ' + order.hotelinfos.rooms[i].room_name + '\n',
                                        bold: true
                                    },
                                    order.hotelinfos.gruppe[i].erwachsene + ' Erwachsene' + (order.hotelinfos.gruppe[i].kinder > 0 ? ', ' + order.hotelinfos.gruppe[i].kinder + ' Kinder' : ""),
                                ],
                            margin: [0, 8, 0, 0]
                        }
                    ],
                );
                prices.push(
                    [

                        {
                            text: (order.hotelinfos.total_price_eur > 0 ? ('\n' + formatPrice(order.hotelinfos.price_per_group[i].price) + ' €') : ('\n' + formatPrice(order.hotelinfos.rateplans[i].room_price) + ' €')),
                            alignment: 'right',

                        },
                        {
                            text:
                                (order.hotelinfos.currency === 'TRY' ? ('(' + formatPrice(order.hotelinfos.rateplans[i].room_price) + ' TL)') : "") +

                                (order.hotelinfos.currency === 'USD' ?
                                        (
                                            (order.hotelinfos.total_price_try > 0 ?
                                                ('( ' + (formatPrice(order.hotelinfos.total_price_try) + ' TL | ')) : '(') +
                                            (formatPrice(order.hotelinfos.rateplans[i].room_price) + ' USD)')
                                        )
                                        :
                                        ((order.hotelinfos.total_price_try > 0 ? ('( ' + (formatPrice(order.hotelinfos.price_per_group_try[i].price)) + ' TL)') : ""))
                                ),
                            alignment: 'right', fontSize: 9

                        }
                    ],
                );
            }

            if (order.hotel_owner === '2') {
                groups.push(
                    [
                        {
                            text:
                                [
                                    {
                                        text: 'Gruppe #' + (i + 1) + ' - Zimmer:  ' + order.hotelinfos.rooms[i].room_name_de + '\n',
                                        bold: true
                                    },
                                    order.hotelinfos.gruppe[i].erwachsene + ' Erwachsene' + (order.hotelinfos.gruppe[i].kinder > 0 ? ', ' + order.hotelinfos.gruppe[i].kinder + ' Kinder' : ""),
                                ],
                            margin: [0, 8, 0, 0]
                        }
                    ],
                );
                prices.push(
                    [

                        {
                            text: (order.hotelinfos.total_price_eur > 0 ? ('\n' + formatPrice(order.hotelinfos.price_per_group_eur[i]) + ' €') : ('\n' + formatPrice(order.hotelinfos.rateplans[i].room_price) + ' €')),
                            alignment: 'right',

                        },
                        {
                            text: (order.hotelinfos.total_price_try > 0 ? ('(' + formatPrice(order.hotelinfos.price_per_group_try[i]) + ' TL)') : ""),
                            alignment: 'right', fontSize: 9

                        }
                    ],
                );
            }


        }

        let gesamtpreis = [];
        if (order.hotel_owner === '0' || order.hotel_owner === '1') {
            gesamtpreis.push(
                {
                    text: [
                        'Gesamtbetrag: ' +
                        (order.hotelinfos.total_price_eur > 0 ? (formatPrice(order.hotelinfos.total_price_eur) + ' €') : (formatPrice(order.hotelinfos.total_price) + ' €')),
                        // {
                        //     text: order.hotelinfos.currency === 'USD' ? '(' + formatPrice(order.hotelinfos.total_price) + ' ' + order.hotelinfos.currency + ')' : "",
                        //     fontSize: 10
                        // },
                        {
                            text:
                                (order.hotelinfos.currency === 'TRY' ? ('\n( ' + formatPrice(order.hotelinfos.total_price) + ' TL)') : "") +

                                //Wenn mit USD reserviert wurde und TRY existiert
                                (order.hotelinfos.currency === 'USD' ?
                                        (
                                            (order.hotelinfos.total_price_try > 0 ? ('\n(' + formatPrice(order.hotelinfos.total_price_try) + ' TL | ') : '(') + formatPrice(order.hotelinfos.total_price) + ' USD)'
                                        )
                                        :
                                        ((order.hotelinfos.total_price_try > 0 ? ('\n( ' + (formatPrice(order.hotelinfos.total_price_try)) + ' TL)') : ""))
                                ),

                            alignment: 'right', fontSize: 9

                        }
                    ],
                    alignment: 'right', bold: true,
                    margin: [0, 8, 0, 0],

                },
            );
        }
        if (order.hotel_owner === '2') {
            gesamtpreis.push(
                {
                    text: [
                        'Gesamtbetrag: ' +
                        (order.hotelinfos.total_price_eur > 0 ? (formatPrice(order.hotelinfos.total_price_eur) + ' €') : (formatPrice(order.hotelinfos.total_price) + ' €')) +
                        '\n',

                        {
                            text: order.hotelinfos.total_price_try > 0 ? ('(' + formatPrice(order.hotelinfos.total_price_try) + ' TL)') : "",
                            fontSize: 10
                        },
                    ],
                    alignment: 'right', bold: true,
                    margin: [0, 8, 0, 0]

                },
                // {
                // text: (order.hotelinfos.total_price_eur > 0 ? '(' + formatPrice(order.hotelinfos.total_price) + ' ' + order.hotelinfos.currency + ')' : ""),
                // alignment: 'right', fontSize: 9, bold: true,
                // margin: [0, 2, 0, 0]
                // }
            );
        }
        let rechnungReise = [


                {
                    style: "AnschriftMargin",
                    columns: [
                        [
                            // Anschrift Admin
                            {
                                text: userinfo.kontakt.firma + " - " + userinfo.kontakt.strnr + " - " + userinfo.kontakt.plz + " " + userinfo.kontakt.ort,
                                style: 'AdminAdress'
                            },

                            // Rechnungsanschrift
                            {text: order.order.first_name + " " + order.order.last_name},
                            {text: order.rechnungsadresse.str + " " + order.rechnungsadresse.nr + " " + order.rechnungsadresse.adressz},
                            {text: order.rechnungsadresse.plz + " " + order.rechnungsadresse.ort},
                        ],
                        [


                            {
                                text: 'Reservierungs-Nr.: ' + reservierungsnr,
                                alignment: 'right',
                                margin: [0, 15, 0, 0],
                            },
                            {
                                text: 'Reservierungs-Datum.: ' + moment(order.date).format("DD.MM.YYYY"),
                                alignment: 'right'
                            }

                        ]

                    ]
                },


                {text: 'Reservierungsbestätigung', style: 'Betreff'},

                {
                    style: 'tabelleBeschreibung',
                    table: {
                        headerRows: 1,
                        widths: ['*', 100],

                        body:
                            [

                                rowheader,
                                placeAdress,
                                [groups, prices],


                            ]
                    },
                    layout: 'headerLineOnly',
                },


                //---------------------------- Gesamtpreis -------------------------------

                {

                    table: {
                        headerRows: 1,
                        widths: ['*', 250],
                        body: [
                            [
                                // Damit Header im Tabel leer bleibt
                                '', ''
                            ],

                            [
                                '', gesamtpreis


                                // {
                                //     text:
                                //         'Gesamtbetrag: ' +
                                //         (order.hotelinfos.total_price_eur > 0 ? (formatPrice(order.hotelinfos.total_price_eur) + ' €') : (formatPrice(order.hotelinfos.total_price) + ' €')) +
                                //         (order.hotelinfos.total_price_eur > 0 ? '(' + formatPrice(order.hotelinfos.total_price) + ' ' + order.hotelinfos.currency + ')' : ""),
                                //     alignment: 'right', bold: true,
                                //     margin: [0, 8, 0, 0],
                                //
                                // },


                                // {
                                //     text:
                                //     alignment: 'right', fontSize: 9, bold: true,
                                //     margin: [0, 2, 0, 0]
                                // }

                            ],


                        ]
                    },
                    layout: 'headerLineOnly',

                },

                {
                    text: "Bitte überweisen Sie den Gesamtbetrag auf das unten genannte Konto.",
                    alignment: 'center',
                    margin: [0, 30, 0, 50],
                    fontStyle: 'italic'
                },


            ]
        ;

        return rechnungReise;
    }
