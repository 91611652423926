var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GalleryPopup',{attrs:{"pics":_vm.roominfo.room.photos,"show":_vm.showGalleryPopup}}),_c('div',{staticClass:"flip-card",class:[{selectedroom3 : _vm.isActiv},{notselectedroom3 :! _vm.isActiv}]},[_c('v-icon',{class:[{showChecked3: _vm.isActiv},{hideChecked3 :!_vm.isActiv}],attrs:{"color":"var(--Primary)","large":""}},[_vm._v(" mdi-check ")]),_c('div',{staticClass:"flip-card-inner"},[_c('div',{staticClass:"flip-card-front"},[_c('div',{staticClass:"div-img-cardroom3"},[_c('img',{staticClass:"img-cardroom3",attrs:{"src":_vm.roominfo.room.photos[0],"alt":"room-photo"}})]),_c('div',{staticClass:"div-price-name-cardroom3"},[_c('div',{staticClass:"div-price-cardroom3"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.roominfo.price))+" "),_c('v-icon',{attrs:{"small":"","color":"var(--Primary)"}},[_vm._v(_vm._s(_vm.currencyicon))])],1),_c('label',{staticClass:"label-roomtitel-cardroom3"},[(_vm.roominfo.quantity !== 1)?_c('span',[_vm._v(_vm._s(_vm.roominfo.quantity)+" X ")]):_vm._e(),_vm._v(_vm._s(_vm.roominfo.room.name)+" ")])])]),_c('div',{staticClass:"flip-card-back"},[_c('div',{staticClass:"div-title-card-back"},[(_vm.roominfo.quantity !== 1)?_c('span',[_vm._v(_vm._s(_vm.roominfo.quantity)+" X ")]):_vm._e(),_vm._v(_vm._s(_vm.roominfo.room.name)+" ")]),_c('div',{staticClass:"div-quickinfos-cardroom3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"cursor":"default"}},on),[_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v("mdi-room-service")]),_c('br'),_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("Card.Optionen"))+" ")])],1)]}}])},[_c('div',{staticClass:"div-tooltip-cardroom3"},[_c('label',{staticStyle:{"font-size":"18px","text-align":"center"}},[_vm._v(" -- "+_vm._s(_vm.$t("Card.ServiceOptionen"))+" -- ")]),_vm._l((_vm.roominfo.rate_plans),function(i,index){return [_c('ul',[_c('li',[_vm._v(" "+_vm._s(i.rate_plan.meal_plan_name)+" ")])])]})],2)]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"cursor":"default"}},on),[(_vm.roominfo.confirmation === 'instant')?_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v("mdi-check-decagram")]):_vm._e(),(_vm.roominfo.confirmation === 'delayed')?_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v("mdi-hours-24")]):_vm._e(),_c('br'),_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("Card.Bestaetigung"))+" ")])],1)]}}])},[_c('div',{staticClass:"div-tooltip-cardroom3"},[_c('label',{staticStyle:{"font-size":"18px"}},[_vm._v(" -- "+_vm._s(_vm.$t("Card.Buchungsbestaetigung"))+" -- ")]),_c('br'),_c('label',[_vm._v(" "+_vm._s(_vm.roominfo.confirmation === "instant" ? _vm.$t("Card.instant") : "")+" "+_vm._s(_vm.roominfo.confirmation === "delayed" ? _vm.$t("Card.delayed") : "")+" ")])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"cursor":"default"}},on),[_c('v-icon',{attrs:{"large":"","color":"white"}},[_vm._v("mdi-book-cancel")]),_c('br'),_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("Card.Stornierung"))+" ")])],1)]}}])},[_c('div',{staticClass:"div-tooltip-cardroom3"},[_c('label',{staticStyle:{"font-size":"18px"}},[_vm._v(" -- "+_vm._s(_vm.$t("Card.Stornierung"))+" -- ")]),_c('br'),_c('div',{staticStyle:{"text-align":"start","max-width":"300px"},domProps:{"innerHTML":_vm._s(_vm.roominfo.rate_plan.cancellation_policy_html)}})])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"cursor":"default"}},on),[_c('v-icon',{staticStyle:{"cursor":"pointer"},attrs:{"large":"","color":"white"},on:{"click":function($event){_vm.showGalleryPopup= true}}},[_vm._v(" mdi-image-multiple ")]),_c('br'),_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("Card.Bilder"))+" ")])],1)]}}])},[_c('div',{staticClass:"div-tooltip-cardroom3"},[_c('label',{staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.$t("Card.Bilderoffnenklicken"))+" ")])])])],1),_c('div',{staticClass:"div-infos-cardroom3"},[_c('div',{staticClass:"div-infos-cardroom31"},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-silverware-fork-knife")]),_c('label',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.roominfo.rate_plan.meal_plan_name)+" ")])],1)]),_c('div',{staticClass:"div-price-back-cardroom3"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.roominfo.price))+" "),_c('v-icon',{attrs:{"color":"var(--Secondary)"}},[_vm._v(_vm._s(_vm.currencyicon))])],1),_c('div',{staticClass:"div-btn-cardroom3"},[[(_vm.place.groups.length-1 >0)?_c('v-btn',{attrs:{"href":"#title-place"},on:{"click":_vm.selectRoom}},[_vm._v(" "+_vm._s(_vm.$t("Button.ZimmerAuswaehlen"))+" ")]):_c('v-btn',{on:{"click":_vm.selectRoom}},[_vm._v(" "+_vm._s(_vm.$t("Button.ZimmerAuswaehlen"))+" ")])],_c('v-btn',{staticClass:"vbtn-zeigInfos-cardroom3",attrs:{"color":"white","outlined":""},on:{"click":_vm.zeigeInfos}},[_vm._v(" "+_vm._s(_vm.$t("Button.Infos"))+" ")])],2)])])],1),_c('div',{staticStyle:{"z-index":"5"}},[(_vm.showInfos)?_c('OverlayRoomInfos',{attrs:{"roominfo":_vm.roominfo,"show-overlay":_vm.showInfos,"from":"halal"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }