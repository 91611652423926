<template>
  <div>

    <div class="bodyheader-dashboard">
      <label>
        <v-icon class="icon-start" color="white">mdi-finance</v-icon>
        {{ $t("Dashboard.Headertitle") }}
      </label>
    </div>


    <div class="div-body-dashboard">


      <!--      --------------------------- Gesamtumsatz Anfang      --------------------------------------------------->
      <div class="div-gesamtumsatz-dashboard0">
        <div class="div-gesamtumsatz-dashboard">
          <div class="div-gesamtumsatz-dashboard1">
            <label class="label-number-card-dashboard" style="color: #2196f3">
              {{ formatPrice(umsatzGesamt) }} €
            </label>
            <h4>{{ $t("Dashboard.Umsatze") }}</h4>
          </div>
          <div
              class="div-gesamtumsatz-dashboard1"
              v-if="pRolle === 'admin' || pRolle === 'superadmin'"
          >
            <label class="label-number-card-dashboard" style="color: #4caf50">
              {{ formatPrice(provisionadmin) }} €
            </label>
            <h4>{{ $t("Dashboard.Provision") }}</h4>
          </div>


          <div class="div-gesamtumsatz-dashboard1">
            <label class="label-number-card-dashboard" style="color: #ff9800">
              {{ formatPrice(provisionreiseburo) }} €
            </label>
            <h4 v-if="pRolle === 'admin' || pRolle === 'superadmin'">
              {{ $t("Dashboard.ProvisionReiseburo") }}</h4>
            <h4 v-if="pRolle === 'reiseburo'">{{ $t("Dashboard.Provision") }}</h4>
          </div>


          <div
              class="div-gesamtumsatz-dashboard1"
              v-if="pRolle === 'admin' || pRolle === 'superadmin'"
          >
            <label class="label-number-card-dashboard">
              {{ formatPrice(umsatzKundenseiteGesamt) }} €
            </label>
            <h4>{{ $t("Dashboard.UmsatzKunde") }}</h4>
          </div>


          <div
              class="div-gesamtumsatz-dashboard1"
              v-if="pRolle === 'admin' || pRolle === 'superadmin'"
          >
            <label class="label-number-card-dashboard">
              {{ formatPrice(umsatzReiseburoGesamt) }} €
            </label>
            <h4>{{ $t("Dashboard.UmsatzReiseburo") }}</h4>
          </div>

          <div
              class="div-gesamtumsatz-dashboard1"
              v-if="pRolle === 'admin' || pRolle === 'superadmin'"
          >
            <label class="label-number-card-dashboard" style="color: #4caf50">
              {{ formatPrice(gewinnGesamt) }} €
            </label>
            <h4>Gewinn</h4>
          </div>
        </div>

        <div class="div-umsatzstatus-dashboard">

          <div class="div-umsatzstatus-dashboard1" style="background: #2196f3; color: white">
            <h6>{{ $t(this.getOrderState("0")) }}</h6>
            <label>{{ formatPrice(umsatzStatus1Gesamt) }} €</label>
          </div>
          <div class="div-umsatzstatus-dashboard1" style="background: #4C0099; color: white">
            <h6>{{ $t(this.getOrderState("7")) }}</h6>
            <label>{{ formatPrice(umsatzStatus8Gesamt) }} €</label>
          </div>

          <div class="div-umsatzstatus-dashboard1" style="background: yellow;">
            <h6>{{ $t(this.getOrderState("1")) }}</h6>
            <label>{{ formatPrice(this.umsatzStatus2Gesamt) }} €</label>
          </div>

          <div class="div-umsatzstatus-dashboard1" style="background: #4caf50; color: white">
            <h6>{{ $t(this.getOrderState("2")) }}</h6>
            <label>{{ formatPrice(this.umsatzStatus3Gesamt) }} €</label>
          </div>

          <div class="div-umsatzstatus-dashboard1" style="background: grey; color: white">
            <h6>{{ $t(this.getOrderState("3")) }}</h6>
            <label>{{ formatPrice(this.umsatzStatus4Gesamt) }} €</label>
          </div>

          <div class="div-umsatzstatus-dashboard1" style="background: red; color: white">
            <h6>{{ $t(this.getOrderState("4")) }}</h6>
            <label>{{ formatPrice(this.umsatzStatus5Gesamt) }} €</label>
          </div>

          <div class="div-umsatzstatus-dashboard1" style="background: #940000; color: white">
            <h6>{{ $t(this.getOrderState("5")) }}</h6>
            <label>{{ formatPrice(this.umsatzStatus6Gesamt) }} €</label>
          </div>

          <div class="div-umsatzstatus-dashboard1" style="background: black; color: white">
            <h6>{{ $t(this.getOrderState("6")) }}</h6>
            <label>{{ formatPrice(this.umsatzStatus7Gesamt) }} €</label>
          </div>
        </div>
      </div>


      <!--      --------------------------- Gesamtumsatz Ende      --------------------------------------------------->


      <!--      --------------------------- Admin - Reisebüros Anfang      --------------------------------------------------->
      <!--      --------------------------- Welches Reisebüro hat wie viel umsatzt gemacht      --------------------------------------------------->
      <div class="div-agenturen-dashboard" v-if="pRolle === 'admin' || pRolle === 'superadmin'">

        <br>
        <v-divider></v-divider>
        <br>
        <h3>{{ $t("Dashboard.Reiseburos") }}</h3>
        <v-expansion-panels
            v-model="panelreiseburos"
            multiple
        >
          <v-expansion-panel v-for="(item,i) in reiseburoStatistic" :key="i">
            <v-expansion-panel-header style="background: #f1f1f1">
              <div style="display: flex; align-items: center; gap: 10px;">
                <h4>{{ item.firma }}</h4>
                <v-chip
                    color="var(--Primary)"
                    style="color: white"
                    small
                >
                  {{ item.provisionsklasse }}
                </v-chip>

                ({{ item.anzahl }} Reservierungen)

              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>

              <div class="div-body-dashboard1">

                <div class="div-gesamtumsatz-dashboard">
                  <div class="div-gesamtumsatz-dashboard1">
                    <label class="label-number-card-dashboard" style="color: #2196f3">
                      {{ formatPrice(item.gesamtumsatz) }} €
                    </label>
                    <h4>{{ $t("Dashboard.Umsatze") }}</h4>
                  </div>

                  <div class="div-gesamtumsatz-dashboard1">
                    <label class="label-number-card-dashboard" style="color: #4caf50">
                      {{ formatPrice(item.halalbookingProvision) }} €
                    </label>
                    <h4>halal - {{ $t("Dashboard.Provision") }}</h4>
                  </div>


                  <div class="div-gesamtumsatz-dashboard1">
                    <label class="label-number-card-dashboard" style="color: #ff9800">
                      {{ formatPrice(item.provisionRB) }} €
                    </label>
                    <h4>{{ $t("Dashboard.ProvisionReiseburo") }}</h4>
                  </div>
                </div>


                <div class="div-umsatzstatus-dashboard">

                  <div class="div-umsatzstatus-dashboard1" style="background: #2196f3; color: white">
                    <h6>{{ $t(getOrderState("0")) }}</h6>
                    <label>{{ formatPrice(item.umsatzStatus1Gesamt) }} €</label>
                  </div>
                  <div class="div-umsatzstatus-dashboard1" style="background: #4C0099; color: white">
                    <h6>{{ $t(getOrderState("7")) }}</h6>
                    <label>{{ formatPrice(item.umsatzStatus8Gesamt) }} €</label>
                  </div>

                  <div class="div-umsatzstatus-dashboard1" style="background: yellow;">
                    <h6>{{ $t(getOrderState("1")) }}</h6>
                    <label>{{ formatPrice(item.umsatzStatus2Gesamt) }} €</label>
                  </div>

                  <div class="div-umsatzstatus-dashboard1" style="background: #4caf50; color: white">
                    <h6>{{ $t(getOrderState("2")) }}</h6>
                    <label>{{ formatPrice(item.umsatzStatus3Gesamt) }} €</label>
                  </div>

                  <div class="div-umsatzstatus-dashboard1" style="background: grey; color: white">
                    <h6>{{ $t(getOrderState("3")) }}</h6>
                    <label>{{ formatPrice(item.umsatzStatus4Gesamt) }} €</label>
                  </div>


                  <div class="div-umsatzstatus-dashboard1" style="background: red; color: white">
                    <h6>{{ $t(getOrderState("4")) }}</h6>
                    <label>{{ formatPrice(item.umsatzStatus5Gesamt) }} €</label>
                  </div>

                  <div class="div-umsatzstatus-dashboard1" style="background: #940000; color: white">
                    <h6>{{ $t(getOrderState("5")) }}</h6>
                    <label>{{ formatPrice(item.umsatzStatus6Gesamt) }} €</label>
                  </div>
                  <div class="div-umsatzstatus-dashboard1" style="background: black; color: white">
                    <h6>{{ $t(getOrderState("6")) }}</h6>
                    <label>{{ formatPrice(item.umsatzStatus7Gesamt) }} €</label>
                  </div>
                </div>

                <!--                <br>-->
                <!--                <hr class="hr-horizontale-linie"/>-->
                <!--                <br>-->

                <!--                Admin Tabelle Reisebüros -->
                <!--                <div class="div-tabelle-dashboard">-->
                <!--                  <v-data-table-->
                <!--                      :headers="headersAdminReiseburo"-->
                <!--                      :items="reiseagenturStatistic[i].data"-->
                <!--                      :items-per-page="5"-->
                <!--                      class="elevation-1"-->
                <!--                      :footer-props="{itemsPerPageText : $t('Dashboard.AnzahlReisenTabellenFooter'), itemsPerPageOptions : [5,7,10,20]}"-->
                <!--                  >-->

                <!--                    <template v-slot:item.title="{ item }" class="tabelle-title-dashboard">-->
                <!--                      {{ item.title }}-->
                <!--                    </template>-->
                <!--                    <template v-slot:item.anzahlreisen="{ item }">-->
                <!--                      {{ item.anzahlreisen }}-->
                <!--                    </template>-->
                <!--                    <template v-slot:item.umsatz="{ item }" style="text-align: end !important;">-->
                <!--                      {{ item.umsatz.toFixed(2).replace('.', ',') }} €-->
                <!--                    </template>-->
                <!--                    <template v-slot:item.provision="{ item }">-->
                <!--                      {{ (item.provisionTagesreise + item.provisionMehrTagesreise).toFixed(2).replace('.', ',') }} €-->
                <!--                    </template>-->
                <!--                    <template v-slot:item.provisionReiseburoGesamt="{ item }">-->
                <!--                      {{ item.provisionReiseburoGesamt.toFixed(2).replace('.', ',') }} €-->
                <!--                    </template>-->
                <!--                  </v-data-table>-->
                <!--                </div>-->
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </div>


      <!--      --------------------------- Admin - Hotels Anfang      --------------------------------------------------->
      <!--      --------------------------- Eine Auflistung aller Hotels, bei denen eine Reservierung eingetroffen ist      --------------------------------------------------->
      <!--      <div class="div-agenturen-dashboard" v-if="pRolle === 'admin' || pRolle === 'superadmin'">-->

      <!--        <br>-->
      <!--        <hr class="hr-horizontale-linie"/>-->
      <!--        <br>-->
      <!--        <h3>Umsatz pro Hotel</h3>-->
      <!--        <v-expansion-panels-->
      <!--            v-model="panelreiseburos"-->
      <!--            multiple-->
      <!--        >-->
      <!--          <v-expansion-panel v-for="(item,i) in reiseagenturStatistic" :key="i">-->
      <!--            <v-expansion-panel-header>-->
      <!--              <div>-->
      <!--                <h4>{{ item.reiseburo }}</h4>-->
      <!--              </div>-->
      <!--            </v-expansion-panel-header>-->
      <!--            <v-expansion-panel-content>-->

      <!--              <div class="div-body-dashboard1">-->

      <!--                <div class="div-gesamtumsatz-dashboard">-->
      <!--                  <div class="div-gesamtumsatz-dashboard1">-->
      <!--                    <label class="label-number-card-dashboard" style="color: #2196f3">-->
      <!--                      {{ parseFloat(item.umsatzReiseburoGesamt).toFixed(2).replace('.', ',') }} €-->
      <!--                    </label>-->
      <!--                    <h4>{{ $t("Dashboard.Umsatze") }}</h4>-->
      <!--                  </div>-->

      <!--                  <div class="div-gesamtumsatz-dashboard1">-->
      <!--                    <label class="label-number-card-dashboard" style="color: #4caf50">-->
      <!--                      {{-->
      <!--                        parseFloat(item.provisionTagesreise + item.provisionMehrTagesreise).toFixed(2).replace('.', ',')-->
      <!--                      }} €-->
      <!--                    </label>-->
      <!--                    <h4>{{ $t("Dashboard.Provision") }}</h4>-->
      <!--                  </div>-->


      <!--                  <div class="div-gesamtumsatz-dashboard1">-->
      <!--                    <label class="label-number-card-dashboard" style="color: #ff9800">-->
      <!--                      {{ parseFloat(item.provisionReiseburoGesamt).toFixed(2).replace('.', ',') }} €-->
      <!--                    </label>-->
      <!--                    <h4>{{ $t("Dashboard.ProvisionReiseburo") }}</h4>-->
      <!--                  </div>-->
      <!--                </div>-->


      <!--                <div class="div-umsatzstatus-dashboard">-->

      <!--                  <div class="div-umsatzstatus-dashboard1" style="background: #2196f3; color: white">-->
      <!--                    <h6>{{ $t(getOrderState("1")) }}</h6>-->
      <!--                    <label>{{ parseFloat(item.umsatzStatus1Gesamt).toFixed(2).replace('.', ',') }} €</label>-->
      <!--                  </div>-->

      <!--                  <div class="div-umsatzstatus-dashboard1" style="background: yellow;">-->
      <!--                    <h6>{{ $t(getOrderState("2")) }}</h6>-->
      <!--                    <label>{{ parseFloat(item.umsatzStatus2Gesamt).toFixed(2).replace('.', ',') }} €</label>-->
      <!--                  </div>-->

      <!--                  <div class="div-umsatzstatus-dashboard1" style="background: #4caf50; color: white">-->
      <!--                    <h6>{{ $t(getOrderState("3")) }}</h6>-->
      <!--                    <label>{{ parseFloat(item.umsatzStatus3Gesamt).toFixed(2).replace('.', ',') }} €</label>-->
      <!--                  </div>-->

      <!--                  <div class="div-umsatzstatus-dashboard1" style="background: grey; color: white">-->
      <!--                    <h6>{{ $t(getOrderState("4")) }}</h6>-->
      <!--                    <label>{{ parseFloat(item.umsatzStatus4Gesamt).toFixed(2).replace('.', ',') }} €</label>-->
      <!--                  </div>-->

      <!--                  <div class="div-umsatzstatus-dashboard1" style="background: red; color: white">-->
      <!--                    <h6>{{ $t(getOrderState("5")) }}</h6>-->
      <!--                    <label>{{ parseFloat(item.umsatzStatus5Gesamt).toFixed(2).replace('.', ',') }} €</label>-->
      <!--                  </div>-->

      <!--                  <div class="div-umsatzstatus-dashboard1" style="background: #940000; color: white">-->
      <!--                    <h6>{{ $t(getOrderState("6")) }}</h6>-->
      <!--                    <label>{{ parseFloat(item.umsatzStatus6Gesamt).toFixed(2).replace('.', ',') }} €</label>-->
      <!--                  </div>-->
      <!--                </div>-->

      <!--                <br>-->
      <!--                <hr class="hr-horizontale-linie"/>-->
      <!--                <br>-->

      <!--                &lt;!&ndash;                Admin Tabelle Reisebüros &ndash;&gt;-->
      <!--                <div class="div-tabelle-dashboard">-->
      <!--                  <v-data-table-->
      <!--                      :headers="headersAdminReiseburo"-->
      <!--                      :items="reiseagenturStatistic[i].data"-->
      <!--                      :items-per-page="5"-->
      <!--                      class="elevation-1"-->
      <!--                      :footer-props="{itemsPerPageText : $t('Dashboard.AnzahlReisenTabellenFooter'), itemsPerPageOptions : [5,7,10,20]}"-->
      <!--                  >-->

      <!--                    <template v-slot:item.title="{ item }" class="tabelle-title-dashboard">-->
      <!--                      {{ item.title }}-->
      <!--                    </template>-->
      <!--                    <template v-slot:item.anzahlreisen="{ item }">-->
      <!--                      {{ item.anzahlreisen }}-->
      <!--                    </template>-->
      <!--                    <template v-slot:item.umsatz="{ item }" style="text-align: end !important;">-->
      <!--                      {{ item.umsatz.toFixed(2).replace('.', ',') }} €-->
      <!--                    </template>-->
      <!--                    <template v-slot:item.provision="{ item }">-->
      <!--                      {{ (item.provisionTagesreise + item.provisionMehrTagesreise).toFixed(2).replace('.', ',') }} €-->
      <!--                    </template>-->
      <!--                    <template v-slot:item.provisionReiseburoGesamt="{ item }">-->
      <!--                      {{ item.provisionReiseburoGesamt.toFixed(2).replace('.', ',') }} €-->
      <!--                    </template>-->
      <!--                  </v-data-table>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </v-expansion-panel-content>-->
      <!--          </v-expansion-panel>-->
      <!--        </v-expansion-panels>-->

      <!--      </div>-->

      <!--    ----------------------------------- Tabelle für Reisebür -------------------------------------------------   -->
      <!--      <div class="div-tabelle-dashboard" v-if="pRolle === 'reiseburo'">-->
      <!--        <h3>{{ $t("Dashboard.Reisen") }}</h3>-->
      <!--        <v-data-table-->
      <!--            :headers="headersReiseburo"-->
      <!--            :items="ownValues"-->
      <!--            :items-per-page="5"-->
      <!--            class="elevation-1"-->
      <!--            :footer-props="{itemsPerPageText : $t('Dashboard.AnzahlReisenTabellenFooter'), itemsPerPageOptions : [5,7,10,20]}"-->
      <!--        >-->

      <!--          <template v-slot:item.umsatz="{ item }">-->
      <!--            {{ item.umsatz.toFixed(2).replace('.', ',') }} €-->
      <!--          </template>-->
      <!--          <template v-slot:item.provisionReiseburoGesamt="{ item }">-->
      <!--            {{ item.provisionReiseburoGesamt.toFixed(2).replace('.', ',') }} €-->
      <!--          </template>-->
      <!--        </v-data-table>-->

      <!--      </div>-->


    </div>
  </div>

</template>

<script>
import {getAllOrder, getOrderByFirmaID} from "@/util/orderUtil";
import {getOrderState} from "@/util/OrderState";
import {formatPrice} from "@/util/currency";
import {gethpr} from "@/util/settingsUtil";
import {getReiseburoStatistic} from "@/util/statisticUtil";


export default {
  name: "Dashboard",
  props: [
    'pLang',
    'pRolle',
    'pUser',
  ],
  data() {
    return {

      // Panel: für das auf und zuklappen der Accordions
      panelagenturen: [],
      panelreiseburos: [],

      lang: localStorage.getItem('lang'),


      umsatz: 0.00,
      offene: 0.00,
      orders: "",
      hpr: "",


      umsatzKundenseiteGesamt: 0.00,
      umsatzReiseburoGesamt: 0.00,
      gewinnGesamt: 0.00,

      umsatzStatus1Gesamt: 0.00,
      umsatzStatus2Gesamt: 0.00,
      umsatzStatus3Gesamt: 0.00,
      umsatzStatus4Gesamt: 0.00,
      umsatzStatus5Gesamt: 0.00,
      umsatzStatus6Gesamt: 0.00,
      umsatzStatus7Gesamt: 0.00,
      umsatzStatus8Gesamt: 0.00,

      umsatzGesamt: 0.00,
      provisionadmin: 0.00,
      provisionreiseburo: 0.00,

      reiseburos: [],
      reiseburoStatistic: [],
      ownValues: [],


      headers: [
        {text: this.$t('Dashboard.Reise'), align: 'start, tabelle-title-dashboard', value: 'title'},
        {text: this.$t('Dashboard.AnzahlRes'), align: 'end, tabelle-zahl-dashboard', value: 'anzahlreisen'},
        {text: this.$t('Dashboard.Umsatz'), align: 'end, tabelle-zahl-dashboard', value: 'umsatz'},
        {text: this.$t('Dashboard.Provision'), align: 'end, tabelle-zahl-dashboard', value: 'provision'},
        {
          text: this.$t('Dashboard.UmsatzReiseburo'),
          align: 'end, tabelle-zahl-dashboard',
          value: 'umsatzReiseburoGesamt'
        },
        {
          text: this.$t('Dashboard.ProvisionReiseburo'),
          align: 'end, tabelle-zahl-dashboard',
          value: 'provisionReiseburoGesamt'
        },
      ],
      headersAdminReiseburo: [
        {text: this.$t('Dashboard.Reise'), align: 'start, tabelle-title-dashboard', value: 'title'},
        {text: this.$t('Dashboard.AnzahlRes'), align: 'end, tabelle-zahl-dashboard', value: 'anzahlreisen'},
        {text: this.$t('Dashboard.Umsatz'), align: 'end, tabelle-zahl-dashboard', value: 'umsatz'},
        {text: this.$t('Dashboard.Provision'), align: 'end, tabelle-zahl-dashboard', value: 'provision'},
        {
          text: this.$t('Dashboard.ProvisionReiseburo'),
          align: 'end, tabelle-zahl-dashboard',
          value: 'provisionReiseburoGesamt'
        },
      ],
      headersAgentur: [
        {text: this.$t('Dashboard.Reise'), align: 'start, tabelle-title-dashboard', value: 'title'},
        {text: this.$t('Dashboard.AnzahlRes'), align: 'end, tabelle-zahl-dashboard', value: 'anzahlreisen'},
        {text: this.$t('Dashboard.Umsatz'), align: 'end, tabelle-zahl-dashboard', value: 'umsatz'},
        {text: this.$t('Dashboard.Provision'), align: 'end, tabelle-zahl-dashboard', value: 'provision'},
        {
          text: this.$t('Dashboard.UmsatzReiseburo'),
          align: 'end, tabelle-zahl-dashboard',
          value: 'umsatzReiseburoGesamt'
        },
      ],
      headersReiseburo: [
        {text: this.$t('Dashboard.Reise'), align: 'start, tabelle-title-dashboard', value: 'title'},
        {text: this.$t('Dashboard.AnzahlRes'), align: 'end, tabelle-zahl-dashboard', value: 'anzahlreisen'},
        {text: this.$t('Dashboard.Umsatz'), align: 'end, tabelle-zahl-dashboard', value: 'umsatz'},
        {text: this.$t('Dashboard.Provision'), align: 'end, tabelle-zahl-dashboard', value: 'provisionReiseburoGesamt'},
      ],
    }
  },

  methods: {
    getOrderState,
    formatPrice
  },


  async created() {

    await new Promise(resolve => setTimeout(resolve, 500));
    if (this.pRolle === "admin" || this.pRolle === "superadmin") {
      this.orders = await getAllOrder();
      this.hpr = await gethpr();
      //this.totalsum ;

      this.orders.map((item, index) => {

        if (item.hotelinfos.total_price_eur === 0) {
          if (item.order_state !== "4") {
            this.umsatzGesamt += parseFloat(item.hotelinfos.total_price);

            if (item.hotel_owner === '1' || item.hotel_owner === '2') {
              this.provisionadmin += (parseFloat(item.hotelinfos.total_price) * parseFloat(item.hotelinfos.OHP.ohp_provision)) / 100;
            } else {

              this.provisionadmin += (parseFloat(item.hotelinfos.total_price) * parseFloat(item.hotelinfos.halalbookingProvision)) / 100;
            }

          }

          // -------------------- Umsatz pro Status --------------------
          if (item.order_state === "0") {
            this.umsatzStatus1Gesamt += parseFloat(item.hotelinfos.total_price);
          }
          if (item.order_state === "1") {
            this.umsatzStatus2Gesamt += parseFloat(item.hotelinfos.total_price);
          }
          if (item.order_state === "2") {
            this.umsatzStatus3Gesamt += parseFloat(item.hotelinfos.total_price);
          }
          if (item.order_state === "3") {
            this.umsatzStatus4Gesamt += parseFloat(item.hotelinfos.total_price);
          }
          if (item.order_state === "4") {
            this.umsatzStatus5Gesamt += parseFloat(item.hotelinfos.total_price);
          }
          if (item.order_state === "5") {
            this.umsatzStatus6Gesamt += parseFloat(item.hotelinfos.total_price);
          }
          if (item.order_state === "6") {
            this.umsatzStatus7Gesamt += parseFloat(item.hotelinfos.total_price);
          }
          if (item.order_state === "7") {
            this.umsatzStatus8Gesamt += parseFloat(item.hotelinfos.total_price);
          }
          // -------------------- Umsatz pro Status ENDE --------------------


          if (item.order_state !== "4") {
            if (item.order_by === "1") {
              this.umsatzKundenseiteGesamt += parseFloat(item.hotelinfos.total_price);
            }
            if (item.order_by === "2") {
              this.umsatzReiseburoGesamt += parseFloat(item.hotelinfos.total_price);
            }
            if (item.hotelinfos.reiseburo) {
              this.provisionreiseburo += (parseFloat(item.hotelinfos.total_price) * parseFloat(item.hotelinfos.reiseburo.provisionRB)) / 100;
            }
          }

        } else {

          if (item.order_state !== "4") {
            this.umsatzGesamt += parseFloat(item.hotelinfos.total_price_eur);

            if (item.hotel_owner === '1' || item.hotel_owner === '2') {
              this.provisionadmin += (parseFloat(item.hotelinfos.total_price_eur) * parseFloat(item.hotelinfos.OHP.ohp_provision)) / 100;
            } else {
              this.provisionadmin += (parseFloat(item.hotelinfos.total_price_eur) * parseFloat(item.hotelinfos.halalbookingProvision)) / 100;
            }
          }

          // -------------------- Umsatz pro Status --------------------
          if (item.order_state === "0") {
            this.umsatzStatus1Gesamt += parseFloat(item.hotelinfos.total_price_eur);
          }
          if (item.order_state === "1") {
            this.umsatzStatus2Gesamt += parseFloat(item.hotelinfos.total_price_eur);
          }
          if (item.order_state === "2") {
            this.umsatzStatus3Gesamt += parseFloat(item.hotelinfos.total_price_eur);
          }
          if (item.order_state === "3") {
            this.umsatzStatus4Gesamt += parseFloat(item.hotelinfos.total_price_eur);
          }
          if (item.order_state === "4") {
            this.umsatzStatus5Gesamt += parseFloat(item.hotelinfos.total_price_eur);
          }
          if (item.order_state === "5") {
            this.umsatzStatus6Gesamt += parseFloat(item.hotelinfos.total_price_eur);
          }
          if (item.order_state === "6") {
            this.umsatzStatus7Gesamt += parseFloat(item.hotelinfos.total_price_eur);
          }
          if (item.order_state === "7") {
            this.umsatzStatus8Gesamt += parseFloat(item.hotelinfos.total_price_eur);
          }

          // -------------------- Umsatz pro Status ENDE --------------------


          if (item.order_state !== "4") {
            if (item.order_by === "1") {
              this.umsatzKundenseiteGesamt += parseFloat(item.hotelinfos.total_price_eur);
            }
            if (item.order_by === "2") {
              this.umsatzReiseburoGesamt += parseFloat(item.hotelinfos.total_price_eur);
            }
            if (item.hotelinfos.reiseburo) {
              this.provisionreiseburo += (parseFloat(item.hotelinfos.total_price_eur) * parseFloat(item.hotelinfos.reiseburo.provisionRB)) / 100;
            }
          }
        }
      })

      this.gewinnGesamt = this.provisionadmin - this.provisionreiseburo;


      let rburos = await getReiseburoStatistic();
      // this.reiseburoStatistic = rburos;

      let rbStat = [];
      rburos.map(item => {
        let gesamtumsatz = 0.0;
        let provisionRB = 0.0;
        let provisionHalal = 0.0;
        let umsatzStatus1Gesamt = 0.0;
        let umsatzStatus2Gesamt = 0.0;
        let umsatzStatus3Gesamt = 0.0;
        let umsatzStatus4Gesamt = 0.0;
        let umsatzStatus5Gesamt = 0.0;
        let umsatzStatus6Gesamt = 0.0;
        let umsatzStatus7Gesamt = 0.0;
        let umsatzStatus8Gesamt = 0.0;
        let anzahl = item.data.length;
        item.data.map(it => {
          let price = 0.0;


          if (it.total_price_eur !== 0) {
            if (it.order_state !== "4") {
              gesamtumsatz += parseFloat(it.total_price_eur);

              provisionRB += (parseFloat(it.total_price_eur) * parseFloat(it.provision)) / 100;

              if (it.halalbookingProvision) {
                provisionHalal += (parseFloat(it.total_price_eur) * parseFloat(it.halalbookingProvision)) / 100;
              }
            }
            price = parseFloat(it.total_price_eur);

          } else {
            if (it.order_state !== "4") {
              gesamtumsatz += parseFloat(it.total_price);

              provisionRB += (parseFloat(it.total_price) * parseFloat(it.provision)) / 100;
              if (it.halalbookingProvision) {
                provisionHalal += (parseFloat(it.total_price) * parseFloat(it.halalbookingProvision)) / 100;
              }
            }
            price = parseFloat(it.total_price);
          }


          // -------------------- Umsatz pro Status --------------------
          if (it.order_state === "0") {
            umsatzStatus1Gesamt += price;
          }
          if (it.order_state === "1") {
            umsatzStatus2Gesamt += price;
          }
          if (it.order_state === "2") {
            umsatzStatus3Gesamt += price;
          }
          if (it.order_state === "3") {
            umsatzStatus4Gesamt += price;
          }
          if (it.order_state === "4") {
            umsatzStatus5Gesamt += price;
          }
          if (it.order_state === "5") {
            umsatzStatus6Gesamt += price;
          }
          if (it.order_state === "6") {
            umsatzStatus7Gesamt += price;
          }
          if (it.order_state === "7") {
            umsatzStatus8Gesamt += price;
          }

          // -------------------- Umsatz pro Status ENDE --------------------
        })
        rbStat.push({
          firma: item.firmaRB,
          provisionsklasse: item.provisionsklasse,
          gesamtumsatz: gesamtumsatz,
          provisionRB: provisionRB,
          halalbookingProvision: provisionHalal,
          umsatzStatus1Gesamt: umsatzStatus1Gesamt,
          umsatzStatus2Gesamt: umsatzStatus2Gesamt,
          umsatzStatus3Gesamt: umsatzStatus3Gesamt,
          umsatzStatus4Gesamt: umsatzStatus4Gesamt,
          umsatzStatus5Gesamt: umsatzStatus5Gesamt,
          umsatzStatus6Gesamt: umsatzStatus6Gesamt,
          umsatzStatus7Gesamt: umsatzStatus7Gesamt,
          umsatzStatus8Gesamt: umsatzStatus8Gesamt,
          anzahl: item.data.length

        });

      })
      this.reiseburoStatistic = rbStat;
    } else {
      let orders = await getOrderByFirmaID(this.pUser.firma_id);
      let umsatzgesamt = 0.0;
      let provision = 0.0;
      orders.map(item => {
        let price = 0.0;

        if (item.order_state !== "4") {
          if (item.hotelinfos.total_price_eur === 0) {
            price += parseFloat(item.hotelinfos.total_price);
            umsatzgesamt += parseFloat(item.hotelinfos.total_price);
            provision += (price * parseFloat(item.hotelinfos.reiseburo.provisionRB)) / 100;

          } else {
            price += parseFloat(item.hotelinfos.total_price_eur);
            umsatzgesamt += parseFloat(item.hotelinfos.total_price_eur);
            provision += (price * parseFloat(item.hotelinfos.reiseburo.provisionRB)) / 100;
          }
        }
        if (item.order_state === "0") {
          this.umsatzStatus1Gesamt += price;
        }
        if (item.order_state === "1") {
          this.umsatzStatus2Gesamt += price;
        }
        if (item.order_state === "2") {
          this.umsatzStatus3Gesamt += price;
        }
        if (item.order_state === "3") {
          this.umsatzStatus4Gesamt += price;
        }
        if (item.order_state === "4") {
          this.umsatzStatus5Gesamt += price;
        }
        if (item.order_state === "5") {
          this.umsatzStatus6Gesamt += price;
        }
        if (item.order_state === "6") {
          this.umsatzStatus7Gesamt += price;
        }
        if (item.order_state === "7") {
          this.umsatzStatus8Gesamt += price;
        }
      })
      this.umsatzGesamt = umsatzgesamt;
      this.provisionreiseburo = provision;
    }

  }
}
</script>

<style scoped>
@import "../css/Dashboard.css";
</style>
